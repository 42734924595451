<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import Swal from "sweetalert2";
import ProjectCreateModal from "@/views/pages/tasks/modals/ProjectCreateModal.vue";
import ProjectUpdateDeleteModal from "@/views/pages/tasks/modals/ProjectUpdateDeleteModal.vue";
import store from "@/state/store";

export default {
  components: {
    Layout,
    PageHeader,
    ProjectCreateModal,
    ProjectUpdateDeleteModal
  },
  data() {
    return {
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Pre-Orders List",
          active: true,
        },
      ],

      projects: []
    }
  },
  computed: {
    isAdmin() {
      return store.state.user.role.toLowerCase() === 'admin'
    }
  },
  methods: {
    async getProjects() {
      try {
        let response = await axios.get('/task/project/list/')
        this.projects = response.data.results
      } catch {
        await Swal.fire({
          icon: "error",
          title: "Couldn't get projects",
        });
        this.projects = []
      }
    },
    isCurrentlySelected(project) {
      return this.$route.params.project_id === project.id.toString()
    },
    onNewProjectCreated(new_project_id) {
      this.$router.push({name: 'project_tasks_list', params: {project_id: new_project_id}})
      this.getProjects()
    }
  },
  mounted() {
    this.getProjects()

    this.socket = new WebSocket(`ws://10.0.2.246:8000/ws/tasks/`);
    this.socket.onmessage = () => {
      this.getProjects()
    };
  },
  beforeUnmount() {
    // Close the WebSocket connection when the component is unmounted
    if (this.socket) {
      this.socket.close();
    }

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="`My Tasks`" :items="items"/>

    <ProjectCreateModal v-if="isAdmin"
                        @onProjectCreated="onNewProjectCreated($event)"
                        ref="projectCreateModal"
    />

    <ProjectUpdateDeleteModal v-if="isAdmin"
                              @onProjectCreated="getProjects()"
                              @onProjectDeleted="getProjects()"
                              ref="projectUpdateDeleteModal"
    />

    <b-row gutter-x="3" gutter-y="3" style="min-height: 100vh" class="mb-4">
      <b-col xl="3" xxl="2">
        <b-card no-body class="h-100">
          <b-card-body>
            <b-button @click="this.$refs.projectCreateModal.openModal()" v-if="isAdmin"
                      variant="success" size="lg" class="w-100 mb-3">Create Project
            </b-button>


            <div>
              <b-list-group>
                <template v-for="project in projects" :key="`project_${project.id}`">
                  <router-link :to="{name: 'project_tasks_list', params: {project_id: project.id}}">
                    <BListGroupItem
                        @mouseover="project.is_hovered = true"
                        @mouseleave="project.is_hovered = false"
                        class="list-group-item project-item cursor-pointer w-100 pb-2"
                        :class="{
                    'bg-soft-success border border-success rounded': isCurrentlySelected(project),
                    'border border-success rounded': project.is_hovered,
                                  }"
                    >
                      <div class="d-flex justify-content-between align-items-start">
                        <h6 class="fs-14 mb-0">{{ project.name }}</h6>

                        <button v-if="isAdmin"
                                @click="this.$refs.projectUpdateDeleteModal.openModal(project)"
                                type="button"
                                class="btn btn-sm btn-icon btn-topbar btn-ghost-success rounded-circle"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                        >
                          <i class="mdi mdi-cog align-middle"></i>
                        </button>
                      </div>
                      <small class="text-muted">
                        {{ project.completed_tasks || 0 }} of {{ project.total_tasks || 0 }}
                        tasks completed
                      </small>
                    </BListGroupItem>
                  </router-link>
                </template>
              </b-list-group>
            </div>

            <!--            <ul class="d-flex flex-column gap-2 mb-0 list-unstyled" id="projectlist-data">-->
            <!--              <template v-for="project in projects" :key="`project_${project.id}`">-->
            <!--                <router-link :to="{name: 'project_tasks_list', params: {project_id: project.id}}">-->
            <!--                  <li @mouseover="project.is_hovered = true"-->
            <!--                      @mouseleave="project.is_hovered = false"-->
            <!--                      class="project-item">-->
            <!--                    <b-button class="w-100 text-start"-->
            <!--                              :variant="isCurrentlySelected(project) ? 'success' : 'outline-success'">{{-->
            <!--                        project.name-->
            <!--                      }}-->
            <!--                    </b-button>-->
            <!--                  </li>-->
            <!--                </router-link>-->
            <!--              </template>-->
            <!--            </ul>-->
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="9" xxl="10">
        <router-view></router-view>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.project-item {
  transition: 0.2s;
}
</style>