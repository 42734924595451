<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ProjectCreateModal",
  emits: ['onProjectCreated'],
  data() {
    return {
      showModal: false,
      is_creating_project: false,
      project_name: '',
    }
  },
  methods: {
    openModal() {
      this.showModal = true
      setTimeout(() => {
        this.$refs['project-name-input'].focus()
      }, 400)
    },
    async createProject() {
      if (this.project_name.trim().length <= 0) {
        await Swal.fire({
          icon: "error",
          title: "Please type project name",
        });
        return
      }
      this.is_creating_project = true
      try {
        let response = await axios.post(`/task/project/create/`, {
          name: this.project_name.trim(),
        })
        this.$emit('onProjectCreated', response.data.id)
        this.showModal = false
        this.project_name = ''
        this.is_creating_project = false
        await Swal.fire({
          icon: "success",
          title: "Project created successfully",
        });
      } catch {
        this.is_creating_project = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't create project",
        });
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Create Project" hide-footer centered>
    <form @submit.prevent="createProject()"
          class="d-flex flex-column gap-3">
      <div>
        <label class="form-label">Project name</label>
        <b-form-input ref="project-name-input" v-model="project_name" :disabled="is_creating_project" type="text"
                      placeholder="Enter project name"></b-form-input>
      </div>
      <b-button :disabled="is_creating_project || project_name.length <= 0"
                :type="is_creating_project ? 'button' : 'submit'" variant="success">
        Create
      </b-button>
    </form>
  </b-modal>
</template>

<style scoped>

</style>