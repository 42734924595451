<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ProjectCreateModal",
  emits: ['onProjectCreated', 'onProjectDeleted'],
  data() {
    return {
      showModal: false,
      is_creating_project: false,
      is_deleting_project: false,
      project: {
        id: null,
        name: ''
      }
    }
  },
  methods: {
    openModal(project) {
      if (!project) return alert('Invalid project')
      this.project = project
      this.showModal = true
    },
    async createProject() {
      if (!this.project.id && this.project.name.trim().length <= 0) {
        await Swal.fire({
          icon: "error",
          title: "Please type project name",
        });
        return
      }
      this.is_creating_project = true
      try {
        await axios.put(`/task/project/update/${this.project.id}/`, {
          name: this.project.name.trim(),
        })
        this.$emit('onProjectCreated')
        this.showModal = false
        this.is_creating_project = false
        await Swal.fire({
          icon: "success",
          title: "Project updated successfully",
        });
      } catch {
        this.is_creating_project = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't update project",
        });
      }
    },
    async deleteConfirmation() {
      await Swal.fire({
        icon: "error",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#f06548",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProject()
        }
      });
    },
    async deleteProject() {
      if (!this.project.id) {
        await Swal.fire({
          icon: "error",
          title: "Invalid project",
        });
        return
      }
      this.is_deleting_project = true
      try {
        await axios.delete(`/task/project/delete/${this.project.id}/`)
        this.$emit('onProjectDeleted')
        this.showModal = false
        this.is_deleting_project = false
        await Swal.fire({
          icon: "success",
          title: "Project deleted successfully",
        });
      } catch {
        this.is_deleting_project = false
        await Swal.fire({
          icon: "error",
          title: "Couldn't delete project",
        });
      }
    },
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Project Settings" hide-footer centered>
    <form @submit.prevent="createProject()" v-if="project.id"
          class="d-flex flex-column gap-3">
      <div>
        <label class="form-label">Project name</label>
        <b-form-input v-model="project.name" :disabled="is_creating_project" type="text"
                      placeholder="Enter project name"></b-form-input>
      </div>
      <div class="d-flex justify-content-between gap-3">
        <b-button :disabled="is_creating_project" class="w-100"
                  type="button" variant="outline-danger"
                  @click="deleteConfirmation()"
        >
          Delete
        </b-button>
        <b-button :disabled="is_creating_project || project.name.length <= 0" class="w-100"
                  :type="is_creating_project ? 'button' : 'submit'" variant="success">
          Save
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>